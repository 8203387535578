import barba from '@barba/core';
import { gsap } from "gsap";
import LocomotiveScroll from 'locomotive-scroll';

import {reinitButtons, removeClassByPrefix, isTouchDevice} from './utils/index.js';

//import './components/features.js';
import cursor from './components/cursor.js';
import slider from './components/slider.js';
import menu from './parts/menu.js';
import deviceMotionsPermission from './parts/deviceMotionsPermission.js';
//import // from './parts///.js';

import magneticButton from './animations/magneticButton.js';
import mouseMove from './animations/mouseMove.js';
import {initInAni, checkAni} from './animations/animations.js';

import limonaduro from './components/limonaduro.js';
import generators from './components/generators.js';
import loader from './components/loader.js';

import disclaimer from './parts/disclaimer.js';

let isLoad = false;

if(document.querySelector('.disclaimer')){
    document.querySelector('.disclaimer__button--1').addEventListener('click', (e) => {
        e.preventDefault();
        disclaimer.close();
        initInAni();
        isLoad = true;
    });
}

const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    repeat: true
});

window.addEventListener("load", function(event) {

    loader.init();

    //scroll.update();

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    /*
    window.addEventListener('resize', () => {
    // We execute the same script as before
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    */

    cursor.init();

    menu.init();

    mouseMove.init();

    limonaduro.init();

    deviceMotionsPermission.init();

    generators.init();

    scroll.update();

    if(document.querySelector('.disclaimer')){
        setTimeout(()=> {
            disclaimer.init();
        }, 6000);
    }
            
    if(disclaimer.alreadyAccepted()){
        isLoad = true;
        setTimeout(()=> {
            initInAni();
        }, 6800);
    }

});

// define a global hook
barba.hooks.before((data) => {
    //console.log('before', data);
    
    loader.enter();

    menu.closeMenu();
});

barba.hooks.afterLeave((data) => {
    //console.log('afterleave', data);

    isLoad = false;

    slider.destroy();
});

barba.hooks.beforeEnter((data) => {
    //console.log('beforeEnter', data);

    slider.init();

    // Change class
    let pageBg = data.next.container.getAttribute("data-barba-bg");
    removeClassByPrefix(document.body, 'bg--');
    if(pageBg) document.body.classList.add(`bg--${pageBg}`);

    // Change class
    let hideClasses = data.next.container.getAttribute("data-barba-hide");
    removeClassByPrefix(document.body, 'hide--');
    if(hideClasses){
        let hideClass = hideClasses.split(',');

        hideClass.map((c) => {
            document.body.classList.add(`hide--${c}`);
        });
    }

    // Init buttons
    if(isTouchDevice === false){
        document.querySelectorAll('.button--magnetic').forEach(el => {
            ////console.log(el);
            let mag = new magneticButton(el);
        });
        
        reinitButtons();
    }

});

barba.hooks.enter((data) => {
    //console.log('enter', data);

});

barba.hooks.afterEnter((data) => {
    //console.log('afterEnter', data);
    
    //pathsMorph.init();
    //scrollEvents.init();
});

// define a global hook
barba.hooks.after((data) => {
    //console.log('after', data);

    scroll.update();
    isLoad = true;
    initInAni();

    loader.leave();
});

// basic default transition (with no rules and minimal hooks)
barba.init({
    debug: false,
    timeout: 2000,
    transitions: [{
        name: 'basic-transition',
        leave: function(data) {

            //console.log('ani leave');
            
            document.body.classList.add('inTransition');

            let leave_tl = gsap.timeline({
               onComplete: function() {
                    document.body.classList.remove('inTransition');
                    document.body.classList.add('inTransitionIn');
                }
            });
            
            return leave_tl
            .to('main', {
                duration: 1.3,
                y: -100,
                ease: "power2.in",
            });

        },
        enter: function(data) {
            //console.log('ani enter');

            //var done = this.async();

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

            scroll.scrollTo(0, {
                duration: 10,
                disableLerp: true
            });

            let enter_tl = gsap.timeline({
               onComplete: function() {
                    //scroll.update();
                    //cursor.update();
                } 
            });

            return enter_tl
            .to('main', {
                duration: 0.01,
                y: 50
            });

        },
        after: function(data) {
            //console.log('ani after');

            //var done = this.async();
            let after_tl = gsap.timeline({
               onComplete: function() {
                    document.body.classList.remove('inTransitionIn');
                } 
            });
            
            return after_tl
            .to('main', {
                duration: 1.4,
                y: 0,
                ease: "power4.out",
            }, 0.1);
        }
    }],
    prevent: ({ el }) => el.classList && el.classList.contains('barba-prevent') || el.href.includes('/#')
});



// Utility fn to reinit magnetic buttons
scroll.on('call', (func, way, obj) => {

    if(isLoad !== true) return;

    checkAni(func, way, obj);
    
    if(func === "reinitButtons" && isTouchDevice === false){
        reinitButtons();
    }
});