
const debounce = (func, delay, immediate) => {
  let timerId;
  return (...args) => {
    const boundFunc = func.bind(this, ...args);
    clearTimeout(timerId);
    if (immediate && !timerId) {
      boundFunc();
    }
    const calleeFunc = immediate ? () => { timerId = null } : boundFunc;
    timerId = setTimeout(calleeFunc, delay);
  }
}

const removeClassByPrefix = (node, prefix) => {
	var regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g');
	node.className = node.className.replace(regx, '');
	return node;
}

const reinitButtons = () => {
  var resizeEvent = window.document.createEvent('UIEvents'); 
  resizeEvent.initUIEvent('resize', true, false, window, 0); 
  window.dispatchEvent(resizeEvent);
}

const isTouchDevice = ("ontouchstart" in document.documentElement);

export {debounce, isTouchDevice, reinitButtons, removeClassByPrefix};