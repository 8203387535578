
import { gsap } from "gsap";
 // core version + navigation, pagination modules:
import {reinitButtons} from '../utils/index.js';
import Swiper from 'swiper';

let swiper = null;
let menuIsOpen = false;

const menu_fw_tl = gsap.timeline({
    paused: true
});

const closeMenu = () => {

  if(menuIsOpen === true){
    menu_fw_tl.tweenTo("closeMenu");
    menuIsOpen = false;

    document.body.classList.remove('menuIsOpen');
  }

}

const handleClick = () => {
    document.body.classList.toggle('menuIsOpen');

    if(menuIsOpen === false){
      menu_fw_tl.tweenFromTo(0,"openMenu");
      menuIsOpen = true;
    }else{
      closeMenu();
    }
}

const init = () => {
    
  const swiper = new Swiper('.menu-main__items', {
      speed: 400,
      autoplay: true,
      slidesPerView: 1.5,
      centeredSlides: true,
      initialSlide: 1,
      breakpoints: {
          768: {
              slidesPerView: 3,
              centeredSlides: false,
              initialSlide: 0,
              allowTouchMove: false,
          }
      }
  });

  menu_fw_tl
    .to('.menu-main__bg', {top: 0, duration: 0.8, ease: "power2.inOut"})
    //.to('.button--discover__cont', {y: 200, rotation: -20,  duration: 0.4, ease: "power2.inOut"}, 0.1)
    .from('.menu-main__item', {top: '100%', rotation: 20, duration: 1.4, ease: "elastic.out(1, 0.6)", stagger: { each: 0.1 }}, 0.4)
    .from('.menu-main__path--1 svg', {x: '120%', rotation:-20,  duration: 2, ease: "elastic.out(1, 0.8)"}, 0.45)
    .from('.menu-main__path--2 svg', {x: '120%', rotation:-20,  duration: 2, ease: "elastic.out(1, 0.8)"}, 0.5)
    .from('.menu-main__path--3 svg', {x: '-120%', y: '50%', rotation:-20,  duration: 2, ease: "elastic.out(1, 0.8)"}, 0.55)
    .from('.menu-main__item .menu-main__button', {scale: '0', rotation:-20, duration: 1.4, ease: "elastic.out(1, 0.6)", stagger: { each: 0.1 }}, 1)
    
    .add(() => {
      // Necessario per resettare i bottoni
      if (typeof window !== 'undefined') {
        reinitButtons();
      }
    })
    .addLabel("openMenu")

    .to('.menu-main__bg', {top: "-150%", duration: 1, ease: "power2.inOut"}, "openMenu")
    .to('.menu-main__item', {top: '140%', rotation: 20, duration: 0.4, ease: "power4.in", stagger: { each: 0.1 }}, "openMenu+=0.2")
    .to('.menu-main__path--1 svg', {x: '100%', rotation:20,  duration: 0.4, ease: "power4.in"}, "openMenu+=0.25")
    .to('.menu-main__path--2 svg', {x: '100%', rotation:20,  duration: 0.4, ease: "power4.in"}, "openMenu+=0.35")
    .to('.menu-main__path--3 svg', {x: '-100%', y: '50%', rotation:-20,  duration: 0.4, ease: "power4.in"}, "openMenu+=0.40")
    //.to('.button--discover__cont', {y: 0, rotation: 0,  duration: 0.8, ease: "elastic.out(1, 0.8)"})
    .to('.menu-main__item .menu-main__button', {scale: '0', rotation:-20, duration: 0.1, ease: "elastic.out(1, 0.6)", stagger: { each: 0.1 }})

    .add(() => {

      // Necessario per resettare i bottoni
      if (typeof window !== 'undefined') {
        reinitButtons();
      }
    })
    .addLabel("closeMenu")

  document.querySelectorAll('.toggleMenu').forEach( (el) => {
      el.addEventListener('click', handleClick );
  })
}

const destroy = () => {
    document.querySelectorAll('.toggleMenu').forEach( (el) => {
        el.removeEventListener('click', handleClick );
    })
}

export default {init, destroy, closeMenu};
