 // core version + navigation, pagination modules:
import Swiper, { Pagination } from 'swiper';

// configure Swiper to use modules
Swiper.use([Pagination]);

let swiper = null;

const init = () => {

    if(!document.querySelector('.about-him-container')) return null;
    
    swiper = new Swiper('.about-him-container', {
        speed: 400,
        autoplay: true,
        spaceBetween: 20,
        slidesPerView: 1,
        slidesPerGroup: 1,
        pagination: {
            el: '.about-him-pagination',
            type: 'progressbar',
        },
        breakpoints: {
            768: {
                slidesPerView: 1.5,
                slidesPerGroup: 1,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 40,
            },
            1280: {
                slidesPerView: 2.5,
                slidesPerGroup: 1,
                spaceBetween: 40,
            },
            1600: {
                slidesPerView: 3,
                slidesPerGroup: 1,
                spaceBetween: 40,
            },
        }
    });
}

const destroy = () => {
    if(swiper){
        swiper.destroy();
    }
}

export default {init, destroy};