import { gsap } from "gsap";
import {removeClassByPrefix} from '../utils/index.js';

let count = 0;
//const items = window.phrases;

const changePhrase = () => {
    let items = document.querySelectorAll('.generator-box__img');
    //let itemOutput = document.querySelector('.generator-box__output');

    /*
    let phraseLength = items[count].phrase.length;
    removeClassByPrefix(item, 'bg--');
    item.classList.add(`bg--${items[count].bg}`);
    itemOutput.innerHTML = items[count].phrase;
    let fontSize = `${((phraseLength - 220) * 100) / (69 - 220)}%`;
    item.style.fontSize = fontSize;
    item.style.lineHeight = `1em`;
    */
    
    document.querySelector(`.generator-box__img.show`).classList.remove('show');
    document.querySelector(`.generator-box__img--${count+1}`).classList.add('show');

    document.querySelector(`.generator-box__download.show`).classList.remove('show');
    document.querySelector(`.generator-box__download--${count+1}`).classList.add('show');

    if(count < (items.length-1)){ 
        count++;
    }else{
        count = 0;
    }
}

const generate = () => {

    let timeline = gsap.timeline();

    timeline
    .to('.generator-box__inner, .generator-box__button, .generator-box__download', {'duration': 0.4, autoAlpha: 0, x: -50, rotate: '-10deg', ease: 'Power4.easeIn', stagger: 0.1})
    .add(changePhrase)
    .to('.generator-box__inner, .generator-box__button, .generator-box__download', {'duration': 0.1, x: 50, rotate: '10deg'})
    .to('.generator-box__inner, .generator-box__button, .generator-box__download', {'duration': 0.4, autoAlpha: 1, x: 0, rotate: '0deg', ease: 'Power4.easeOut', stagger: 0.1});
}

const init = () => {

    if(document.querySelector('.generator-box__button')){
        generate();
        document.querySelector('.generator-box__button').addEventListener('click', function(e){
            e.preventDefault();

            generate();
        })
    }
}

export default {init};

