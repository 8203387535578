
import deviceMotionsPermission from '../parts/deviceMotionsPermission.js';
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";

import {_} from 'lodash';

const limonaduroSection = document.querySelector('.limonaduro');

gsap.registerPlugin(Draggable, InertiaPlugin);

let kisses = [];
let kissesN = 10;
const kisses_tl = gsap.timeline({
    paused: true
});
const kisses2_tl = gsap.timeline({
    paused: true
});

const createElements = () => {

    for (let i = 0; i < kissesN; i++) {

        let mouth = document.createElementNS("http://www.w3.org/2000/svg","path");
        mouth.setAttributeNS(null, "d", "M117.26.69A68.93,68.93,0,0,0,99.44,6.63C93,9.86,86.51,15,68.78,30.82,36.55,59.68,22.48,69.71,5.89,75.5L0,77.55,2.47,79a287.69,287.69,0,0,1,41.59,29.71c2.62,2.23,12.88,12.12,22.82,21.91s20.48,19.87,23.43,22.25c20,16.2,38.36,23.29,62.84,24.19,26.57.95,48.91-6.37,69.44-22.86,7.13-5.71,15.3-13.41,25.43-23.91,10.5-10.84,17.44-17.45,26.19-24.67a277.56,277.56,0,0,1,35-24.86c2.81-1.66,5.09-3,5.09-3.14s-2.85-1.14-6.28-2.37c-11.4-4-20.77-9.7-35.17-21.25-7.08-5.66-13.31-11-36.46-31.42-13.26-11.69-19-15.59-27-18.54C200.77.88,197.11.21,188.32.21c-6.8,0-8.37.1-11.65,1.05-6.84,2-13.83,6.23-17.58,10.69l-1.67,2-3.7-3.56a34.88,34.88,0,0,0-19-9.8A60,60,0,0,0,117.26.69Z");  
        mouth.setAttributeNS(null, "stroke", "#D30A00");
        mouth.setAttributeNS(null, "stroke-width", "4px");
        mouth.setAttributeNS(null, "fill", "red");

        let heart = document.createElementNS("http://www.w3.org/2000/svg","path");
        heart.setAttributeNS(null, "d", "M155,104.62c-28.9-14.41-47.61-21.27-39.45-35.54C130,50.31,149.18,64.85,155,65.52c0,0,27.13-15.23,39.45,3.79C205.45,86.35,155,104.78,155,104.62Z");  
        heart.setAttributeNS(null, "fill", "white");

        let group = document.createElementNS("http://www.w3.org/2000/svg","g");

        group.appendChild(mouth);
        group.appendChild(heart);
        
        kisses.push(document.createElementNS("http://www.w3.org/2000/svg","svg"));
        kisses[i].setAttributeNS(null, "width", "314.28");
        kisses[i].setAttributeNS(null, "height", "177.16");
        kisses[i].setAttributeNS(null, "viewBox", "0 0 314.28 177.16");
        kisses[i].appendChild(group);

        document.querySelector('.limonaduro__kisses').appendChild(kisses[i]);
    }
}

const random = (min, max) => {
  if (max == null) { max = min; min = 0; }  
    return Math.random() * (max - min) + min;
}

const init = () => {
    createElements();

    for (let i = 0; i < kissesN; i++) {
        kisses_tl
        .fromTo(
            kisses[i], 2,
            {top:  "100%", left: `${random(-100, 100)}%`, scale: 0.4, ease: "power2.in"},
            {top:  "-100%", left: `${random(-100, 100)}%`, scale: 2},
            i*1.5/10
        )
    }

    kisses_tl.addLabel('end');

    kisses2_tl
    .fromTo(
        '.limonaduro', 0.4,
        {autoAlpha: 0, ease: "power2.out"},
        {autoAlpha: 1},
        0.8
    )
    .fromTo(
        '.limonaduro__content', 0.4,
        {y:  200, autoAlpha: 0},
        {y:  0, autoAlpha: 1, ease: "power4.out"},
        0.8
    )
    .addLabel('end');

    Draggable.create(".limonaduro__img-cont", {
        type: "rotation",
        inertia: true,
    });
}

const showLimoni = () => {
    limonaduroSection.classList.add('show');

    kisses_tl.tweenFromTo(0, "end");
    kisses2_tl.tweenFromTo(0, "end");
}

const hideLimoni = () => {
    limonaduroSection.classList.remove('show');

    kisses_tl.tweenFromTo(0, "end");
    kisses2_tl.tweenFromTo("end", 0);
}

let lastCheck = false;
const checkOrientation = function(e){

    let data = deviceMotionsPermission.getOrientation(e);
    
    if(data.isResting === true && lastCheck === false){
        showLimoni();
        lastCheck = true;
    }else
    if(data.isResting === false && lastCheck === true){
        hideLimoni();
        lastCheck = false;
    }
}


if (typeof window !== 'undefined') {
    window.addEventListener("deviceorientation", _.throttle((e) => checkOrientation(e), 2000, { 'trailing': false }));
}

export default {init};

