
import { gsap } from "gsap";
import {SplitText} from "gsap/SplitText";
import {reinitButtons} from '../utils/index.js';
gsap.registerPlugin(SplitText);

const inDelay = 1000;
const easingElastic = "elastic.out(1, 0.8)";

const checkAni = (func, way, obj) => {
    //console.log(isLoad, 'ok', func);

    let target = obj.target ? obj.target : obj.targetEl;

    if(way === 'enter' && target){
        
        if(func === "animaBlockHero"){
            if(!target.classList.contains('animated')){
                animaBlockHero(target);
                target.classList.add('animated');
            }
        }

        if(func === "animaBlockTitleText"){
            if(!target.classList.contains('animated')){
                animaBlockTitleText(target);
                target.classList.add('animated');
            }
        }

        if(func === "animaText"){
            if(!target.classList.contains('animated')){
                animaText(target);
                target.classList.add('animated');
            }
        }

        if(func === "animaTitle"){
            if(!target.classList.contains('animated')){
                titleAni(target);
                target.classList.add('animated');
            }
        }

        if(func === "animaBlockImgText"){
            if(!target.classList.contains('animated')){
                animaBlockImgText(target);
                target.classList.add('animated');
            }
        }

        if(func === "animaBlockImgDouble"){
            if(!target.classList.contains('animated')){
                animaBlockImgDouble(target);
                target.classList.add('animated');
            }
        }

        if(func === "animaBlockDicono"){
            if(!target.classList.contains('animated')){
                animaBlockDicono(target);
                target.classList.add('animated');
            }
        }

        if(func === "animaBg"){
            if(!target.classList.contains('animated')){
                animaBg(target);
                target.classList.add('animated');
            }
        }
    }
};

const initInAni = () => {

    checkAni('animaBlockHero', 'enter', {target: document.querySelector('[data-scroll-call="animaBlockHero"]')});
    checkAni('animaBlockHero', 'enter', {target: document.querySelector('.block-intro')});
};

const titleAni = (target) => {

    const title_tl = gsap.timeline();

    const title = target.querySelector('.title_toAni');
    const mySplitText = new SplitText(title, {type:"words,chars"});
    const chars = mySplitText.chars; //an array of all the divs that wrap each character

    title_tl
    .to(title, {duration: 0.1, autoAlpha:1})
    .from(chars, {duration: 0.8, autoAlpha: 0, y: '50%', ease: easingElastic, stagger: 0.02});

    return title_tl;
};

const bgAni = (target) => {

    if(!target.getAttribute('data-scroll-relbg')){
        return;
    }

    const toAni = {x: '0%', y: '0%', duration: 2, rotation:0, autoAlpha: 1, ease: easingElastic, stagger: 0.1};
    
    const bg_tl = gsap.timeline();

    const bgs = target.getAttribute('data-scroll-relbg').split(",").map(n => `.stripes-bg__item--${n}`);

    let bgsLeft = bgs.filter(el => document.querySelector(el) ? document.querySelector(el).classList.contains('stripes-bg__item--left') : false );
    let bgsRight = bgs.filter(el => document.querySelector(el) ? document.querySelector(el).classList.contains('stripes-bg__item--right') : false );
    let bgsTopLeft = bgs.filter(el => document.querySelector(el) ? document.querySelector(el).classList.contains('stripes-bg__item--topLeft') : false );
    let bgsBottomLeft = bgs.filter(el => document.querySelector(el) ? document.querySelector(el).classList.contains('stripes-bg__item--bottomLeft') : false );

    bgsLeft = bgsLeft.length > 0 ? document.querySelector(`${bgsLeft} .svgMorph`) : false;
    bgsRight = bgsRight.length > 0 ? document.querySelector(`${bgsRight} .svgMorph`) : false;
    bgsTopLeft = bgsTopLeft.length > 0 ? document.querySelector(`${bgsTopLeft} .svgMorph`) : false;
    bgsBottomLeft = bgsBottomLeft.length > 0 ? document.querySelector(`${bgsBottomLeft} .svgMorph`) : false;

    bg_tl
    .set(bgsTopLeft, {x: '-20%', y: '-100%', rotation:-60, duration: 2} )
    .set(bgsLeft, {x: '120%', rotation:-20, duration: 2})
    .set(bgsRight, {x: '120%', rotation:60, duration: 2})
    .set(bgsBottomLeft, {x: '-20%', y: '100%', rotation:-20,  duration: 2})
    .set(bgs, {autoAlpha: 1})
    ;

    bg_tl
    .to(bgsTopLeft, toAni )
    .to(bgsLeft, toAni, 0.1)
    .to(bgsRight, toAni, 0.2)
    .to(bgsBottomLeft, toAni, 0.3)
    ;

    return bg_tl;
};

const animaBlockHero = (target) => {

    const bh_tl = gsap.timeline({paused: true, onComplete(){
        reinitButtons();
    }});

    const bottle = target.querySelector('.bottle_toAni');
    const button = target.querySelector('.button_toAni');

    bh_tl
    .add(bgAni(target))
    .addLabel('bottleIn')
    .add(titleAni(target), 0.2)
    .from(bottle, {duration: 1, top: '+=30%', rotate: '10deg', ease: easingElastic}, "bottleIn-=1.8")
    .to(bottle, {duration: 1, autoAlpha: 1, ease: easingElastic}, "bottleIn-=1.8")
    .from(button, {duration: 1, autoAlpha: 1, scale: 0, ease: easingElastic}, "bottleIn-=1.6");

    setTimeout(() => {
        bh_tl.play();
    }, inDelay);
};

const animaBlockTitleText = (target) => {

    const btt_tl = gsap.timeline();
    
    const text = target.querySelectorAll('.text_toAni');

    return btt_tl
    .add(bgAni(target))
    .add(titleAni(target), 0.1)
    .from(text, {duration: 0.8, autoAlpha: 0, y: 20, stagger: 0.2, ease: easingElastic}, '-=0.6');
};

const animaText = (target) => {

    const te_tl = gsap.timeline();

    const text = target.querySelectorAll('.text_toAni');

    return te_tl.from(text, {duration: 0.8, autoAlpha: 0, y: 20, stagger: 0.2, ease: easingElastic});
};

const animaBlockImgText = (target) => {

    const bit_tl = gsap.timeline();
    
    let text = target.querySelectorAll('.text_toAni');
    let imgL = target.querySelector('.img_toAni--left');
    let imgR = target.querySelector('.img_toAni--right');

    
    if(imgL){
        bit_tl
        .add(bgAni(target))
        .from(imgL, {duration: 1.2, autoAlpha: 0, x: '-=20%', rotate: '-20deg', ease: easingElastic}, 0.8)
        .add(titleAni(target), '-=1')
        .from(text, {duration: 0.8, autoAlpha: 0, y: 20, stagger: 0.2, ease: "power2.out"}, '-=0.8');
    }
    if(imgR){
        bit_tl
        .add(bgAni(target))
        .add(titleAni(target))
        .from(text, {duration: 0.8, autoAlpha: 0, y: 20, stagger: 0.2, ease: "power2.out"}, '-=0.8')
        .from(imgR, {duration: 1.2, autoAlpha: 0, x: '+=20%', rotate: '20deg', ease: easingElastic}, '-=0.8');
    }

    return bit_tl;
};

const animaBlockImgDouble = (target) => {

    const bid_tl = gsap.timeline();
    
    let imgL = target.querySelector('.img_toAni--left');
    let imgR = target.querySelector('.img_toAni--right');

    return bid_tl
    .add(bgAni(target))
    .from(imgL, {duration: 1.2, autoAlpha: 0, x: '-=20%', rotate: '-20deg', ease: easingElastic})
    .from(imgR, {duration: 1.2, autoAlpha: 0, x: '+=20%', rotate: '20deg', ease: easingElastic}, '-=0.8');
};



const animaBlockDicono = (target) => {

    const bdn_tl = gsap.timeline();
    
    let text = target.querySelectorAll('.text_toAni');
    let cards = target.querySelectorAll('.card_toAni');
    
    bdn_tl
    .add(bgAni(target))
    .add(titleAni(target))
    .from(cards, {duration: 1, autoAlpha: 0, y: '+=40%', rotate: '10deg', stagger: 0.2, ease: easingElastic}, '-=0.6')
    .from(text, {duration: 0.8, autoAlpha: 0, y: 20, stagger: 0.2, ease: easingElastic}, '-=1');

    return bdn_tl;
};

const animaBg = (target) => {

    let p = target.getAttribute('data-item-pos');

    let a = {
        "topLeft": {x: '-20%', y: '-100%', rotation: -80},
        "left": {x: '-120%', y: '0%', rotation:-60},
        "right": {x: '120%', y: '0%', rotation:60},
        "bottomLeft": {x: '-20%', y: '100%', rotation:-20},
        "bottomRight": {x: '100%', y: '20%', rotation:80}
    };

    gsap.set(target, {autoAlpha: 1});

    gsap.from(target, {x: a[p].x, y: a[p].y, rotation: a[p].rotation,  duration: 2, ease: easingElastic});
};


export { animaBlockHero, animaBlockTitleText, checkAni, initInAni };