
import { isTouchDevice } from '../utils/index';

const getOrientation = (e) => {
    
    let axisLimit = 10;

    let AxisB = Math.round(e.beta);
    let AxisG = Math.round(e.gamma);
    let isResting = false;

    if(e.beta && (AxisB >= -axisLimit && AxisB <= axisLimit) && (AxisG >= -axisLimit && AxisG <= axisLimit) ){
        isResting = true;
    }

    let axis = {b: AxisB, g: AxisG, isResting: isResting};

    return axis;
}

const showBannerPermission = (state) => {

    if(state === true){
        document.querySelector('.banner-deviceMotion').classList.add('open');
    }else{
        document.querySelector('.banner-deviceMotion').classList.remove('open');
    }
}

const requestDeviceMotionEvent = () => {
    console.log('clicked');

    DeviceMotionEvent.requestPermission()
        .then(response => {
        if (response === 'granted') {
            showBannerPermission(false);
            
            window.localStorage.setItem('bot-checkMotion', 'yes');
        } else {
            console.log('DeviceMotion permissions not granted.');
            document.querySelector('.banner-deviceMotion__text').innerHTML = "Mah Mah! Non vuoi vivere la tua vita appieno? Complimenti, ti sei perso una bella limonata!";

            document.addEventListener('click', () => {
                showBannerPermission(false); 
                window.localStorage.setItem('bot-checkMotion', 'yes');
            });
        }
        })
        .catch(e => {
            console.error(e);
        });

}

const init = () => {

    let motionGranted = window.localStorage.getItem('bot-checkMotion');

    document.querySelector('.banner-deviceMotion__button').addEventListener("click", (e) => {
        e.preventDefault();
        requestDeviceMotionEvent();
    });

    // Check for permission IOS 13+
    if ( isTouchDevice && DeviceMotionEvent && typeof DeviceMotionEvent.requestPermission === 'function' && motionGranted === null ){
        showBannerPermission(true);
    }

    /*
    // Event listener on devideorientation
    if (typeof window !== 'undefined') {
        window.addEventListener("deviceorientation", getOrientation);
        console.log(axis);
        if(axis !== false){
            showBannerPermission(true);
        }
    }
    */
}

export default {init, getOrientation};
