import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(SplitText);

const pre_tl = gsap.timeline({
    paused: true
});

const init = () => {

    pre_tl
    .to(".loader__base", {morphSVG: {precompile: ["M1366,768 C1366,768 1249,768 682.5,768 110,768 0,768 0,768 0,768 0,768 0,768 0,768 301,768 683,768 1026,768 1366,768 1366,768 1366,768 1366,768 1366,768 z","M1366,768 C1366,768 1249,768 682.5,768 110,768 0,768 0,768 0,767.66667 0,767.33333 0,767 0,767 109,437 683,437 1274,437 1366,767 1366,767 1366,767.33333 1366,767.66667 1366,768 z"], shape: ".loader__enter"}, duration: 1, ease: "power4.in"}, 0.1)
    .to(".loader__base", {morphSVG: {precompile: ["M1366,768 C1366,768 1249,768 682.5,768 110,768 0,768 0,768 0,767.66667 0,767.33333 0,767 0,767 109,437 683,437 1274,437 1366,767 1366,767 1366,767.33333 1366,767.66667 1366,768 z","M1366,768 C1366,768 1249,768 682.5,768 110,768 0,768 0,768 0,512 0,256 0,0 0,0 141,0 683,0 1208,0 1366,0 1366,0 1366,256 1366,512 1366,768 z"], shape: ".loader__enter-end"}, duration: 0.4, ease: "power2.out"})
    .addLabel("loaderEnter")
    .to(".loader__base", {morphSVG: {precompile: ["M1366,768 C1366,768 1249,768 682.5,768 110,768 0,768 0,768 0,512 0,256 0,0 0,0 141,0 683,0 1208,0 1366,0 1366,0 1366,256 1366,512 1366,768 z","M1366,768 C1366,768 1249,458 682.5,458 110,458 0,768 0,768 0,512 0,256 0,0 0,0 141,0 683,0 1208,0 1366,0 1366,0 1366,256 1366,512 1366,768 z"], shape: ".loader__leave"}, duration: 1, ease: "power2.in"})
    .to(".loader__base", {morphSVG: {precompile: ["M1366,0 C1366,0 1208,0 683,0 141,0 0,0 0,0 0,256 0,512 0,768 0,768 110,458 682.5,458 1249,458 1366,768 1366,768 1366,512 1366,256 1366,0 z","M1366,0 C1366,0 1249,0 682.5,0 110,0 0,0 0,0 0,0 0,0 0,0 0,0 141,0 683,0 1208,0 1366,0 1366,0 1366,0 1366,0 1366,0 z"], shape: ".loader__leave-end"}, duration: 0.4, ease: "power2.out"})
    .set('.loader', {display: 'none'})
    .addLabel("loaderLeave");
}

const enter = () => {
    pre_tl.tweenFromTo(0, "loaderEnter");
}

const leave = () => {
    pre_tl.tweenFromTo("loaderEnter", "loaderLeave");
}

export default {init, enter, leave};