
import { gsap } from "gsap";
import { isTouchDevice } from '../utils/index';

const easing = "Power1.easeOut";
const timing = 1;

const moveElements = (xPos, yPos) => {
    
    document.querySelector('[data-mousemoveBg="1"]') ? gsap.to('[data-mousemoveBg="1"]', timing, { ease: easing, x: -50 * xPos, y: -50 * yPos }) : null;
    document.querySelector('[data-mousemoveBg="2"]') ? gsap.to('[data-mousemoveBg="2"]', timing, { ease: easing, x: -80 * xPos, y: -80 * yPos }) : null;
    document.querySelector('[data-mousemoveBg="3"]') ? gsap.to('[data-mousemoveBg="3"]', timing, { ease: easing, x: -120 * xPos, y: -120 * yPos }) : null;
    document.querySelector('[data-mousemoveBg="4"]') ? gsap.to('[data-mousemoveBg="4"]', timing, { ease: easing, x: -150 * xPos, y: -150 * yPos }) : null;

    document.querySelector('[data-mousemove="1"]') ? gsap.to('[data-mousemove="1"]', timing, { ease: easing, x: 50 * xPos, y: 50 * yPos, }) : null;
    document.querySelector('[data-mousemove="2"]') ? gsap.to('[data-mousemove="2"]', timing, { ease: easing, x: 80 * xPos, y: 80 * yPos, }) : null;
    document.querySelector('[data-mousemove="3"]') ? gsap.to('[data-mousemove="3"]', timing, { ease: easing, x: 120 * xPos, y: 120 * yPos, }) : null;
}

const init = () => {
    
    if(isTouchDevice){
        window.addEventListener("deviceorientation", (e) => {

            let x = e.beta/100;
            let y = e.gamma/100;

            moveElements(y, x);
        }, true);
    }else{
        
        document.addEventListener('mousemove', (e) => {
            
            let x = (e.clientX/window.innerWidth)-0.5,
                y = (e.clientY/window.innerHeight)-0.5;

            moveElements(x, y);
        });
    }
}

const destroy = () => {
    document.removeEventListener('mousemove', moveElements);
}

export default {init, destroy};