import { gsap } from "gsap";
import {SplitText} from "gsap/SplitText";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(SplitText);

const shape = {
    "2" : "M1366,768S1077,467,683,467,0,768,0,768V0H1366Z",
    "3" : "M1366,768S1077,467,683,467,0,768,0,768V0H1366Z",
}

const intro_tl = gsap.timeline({
    paused: true,
    onComplete: () => {
        if (typeof window !== 'undefined') {
            window.dispatchEvent(new Event('resize'));
        }
    }
});

const alreadyAccepted = () => {

    let ofAge = window.localStorage.getItem('bot-checkIsOk');

    return (ofAge === 'yes');
}

const init = () => {

    if(alreadyAccepted()){
        document.querySelector('.disclaimer').style.display = 'none';
        return null;
    }

    const mySplitText = new SplitText(".disclaimer__title", {type:"words,chars"});
    const chars = mySplitText.chars; //an array of all the divs that wrap each character
    
    intro_tl
    .to('.disclaimer__container', {duration: 0.1, autoAlpha: 1})
    .from(chars, {duration: 0.4, opacity: 0 , y: '50%',  ease: "power1.out", stagger: 0.03})
    .from('.text--big, .disclaimer__button--1 .button__inner, .disclaimer__button--2 .button__inner', {duration: 1, opacity: 0 , y: '50%',  ease: "power4.out", stagger: 0.2}, '-=0.4')
    .add(() => {
        // Necessario per resettare i bottoni
        if (typeof window !== 'undefined') {
            window.dispatchEvent(new Event('resize'));
        }
    })
    .addLabel("introVisible")

    .to(chars, {duration: 0.6, autoAlpha: 0 , y: '-10%',  ease: "power2.in", stagger: 0.01})
    .to('.text--big, .disclaimer__button--1, .disclaimer__button--2', {duration: 0.4, autoAlpha: 0 , y: '-100%',  ease: "power2.in", stagger: 0.01}, '-=0.4')

    .to(".disclaimer__bg1", {morphSVG: {shape: ".disclaimer__bg2"}, duration: 1, ease: "power4.in"}, '-=0.8')
    .to(".disclaimer__bg1", {morphSVG: {shape: ".disclaimer__bg3"}, duration: 0.4, ease: "power2.out"})

    .to('.disclaimer', {autoAlpha:0, duration: 0.1}, '+=1')
    .addLabel("introRemoved");

    setTimeout(() => {
        intro_tl.tweenFromTo(0, "introVisible");
    }, 2000)

}

const close = () => {

    intro_tl.tweenFromTo("introVisible", "introRemoved");

    if (typeof window !== 'undefined') {
        window.localStorage.setItem('bot-checkIsOk', 'yes');
    }
}

export default {init, close, alreadyAccepted};