
let cursor = document.createElement("div");

const isMobile = () => {
    const ua = navigator.userAgent;
    return /Android|Mobi/i.test(ua);
};

const setHidden = (state) => {
    if(state === false){
        cursor.classList.remove("cursor--hidden");
    }else{
        cursor.classList.add("cursor--hidden");
    }
}

const setClicked = (state) => {
    
    if(state === true){
        cursor.classList.add("cursor--clicked");
    }else{
        cursor.classList.remove("cursor--clicked");
    }
}

const setLinkHovered = (state, className) => {
    if(state === true){
        cursor.classList.add(className);
    }else{
        cursor.classList.remove(className);
    }
}

const onMouseMove = (e) => {
    cursor.style.left = `${e.clientX}px`;
    cursor.style.top = `${e.clientY}px`;
}

const onMouseLeave = () => {
    setHidden(true);
};

const onMouseEnter = () => {
    setHidden(false);
};

const onMouseDown = () => {
    setClicked(true);
};

const onMouseUp = () => {
    setClicked(false);
};

const init = () => {

    if (typeof navigator !== 'undefined' && isMobile()) return null;

    let arrowLeft = document.createElement("div");
    let arrowRight = document.createElement("div");
    
    cursor.classList.add('cursor');
    arrowLeft.classList.add('arrow--left', 'arrow');
    arrowRight.classList.add('arrow--right', 'arrow');

    cursor.appendChild(arrowLeft);
    cursor.appendChild(arrowRight);
    document.body.appendChild(cursor);
    
    document.addEventListener("mousemove", onMouseMove);

    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mouseenter", onMouseEnter);
    document.addEventListener("mouseleave", onMouseLeave);

    document.querySelectorAll(".slider-wrapper .wrapper").forEach(el => {
        el.addEventListener("mouseover", () => setLinkHovered(true, 'cursor--slider-hovered'));
        el.addEventListener("mouseout", () => setLinkHovered(false, 'cursor--slider-hovered'));
    });

    document.querySelectorAll("a, button").forEach(el => {
        el.addEventListener("mouseover", () => setLinkHovered(true, 'cursor--link-hovered'));
        el.addEventListener("mouseout", () => setLinkHovered(false, 'cursor--link-hovered'));
    });

    document.querySelectorAll(".cursorPlus").forEach(el => {
        el.addEventListener("mouseover", () => setLinkHovered(true, 'cursor--link-plus'));
        el.addEventListener("mouseout", () => setLinkHovered(false, 'cursor--link-plus'));
    });
}

const destroy = () => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mousedown", onMouseDown);
    document.removeEventListener("mouseup", onMouseUp);
    document.removeEventListener("mouseenter", onMouseEnter);
    document.removeEventListener("mouseleave", onMouseLeave);

    document.querySelectorAll(".slider-wrapper .wrapper").forEach(el => {
        el.removeEventListener("mouseover", setLinkHovered);
        el.removeEventListener("mouseout", setLinkHovered);
    });

    document.querySelectorAll("a, button").forEach(el => {
        el.removeEventListener("mouseover", setLinkHovered);
        el.removeEventListener("mouseout", setLinkHovered);
    });

    document.querySelectorAll(".cursorPlus").forEach(el => {
        el.removeEventListener("mouseover", setLinkHovered);
        el.removeEventListener("mouseout", setLinkHovered);
    });
}

const update = () => {

    // Destroy
    document.querySelectorAll(".slider-wrapper .wrapper").forEach(el => {
        el.removeEventListener("mouseover", setLinkHovered);
        el.removeEventListener("mouseout", setLinkHovered);
    });

    document.querySelectorAll("a, button").forEach(el => {
        el.removeEventListener("mouseover", setLinkHovered);
        el.removeEventListener("mouseout", setLinkHovered);
    });

    document.querySelectorAll(".cursorPlus").forEach(el => {
        el.removeEventListener("mouseover", setLinkHovered);
        el.removeEventListener("mouseout", setLinkHovered);
    });
    
    // Reinit
    document.querySelectorAll(".slider-wrapper .wrapper").forEach(el => {
        el.addEventListener("mouseover", () => setLinkHovered(true, 'cursor--slider-hovered'));
        el.addEventListener("mouseout", () => setLinkHovered(false, 'cursor--slider-hovered'));
    });

    document.querySelectorAll("a, button").forEach(el => {
        el.addEventListener("mouseover", () => setLinkHovered(true, 'cursor--link-hovered'));
        el.addEventListener("mouseout", () => setLinkHovered(false, 'cursor--link-hovered'));
    });

    document.querySelectorAll(".cursorPlus").forEach(el => {
        el.addEventListener("mouseover", () => setLinkHovered(true, 'cursor--link-plus'));
        el.addEventListener("mouseout", () => setLinkHovered(false, 'cursor--link-plus'));
    });
    
    cursor.className = 'cursor';

}

export default {init, destroy, update};
